import { defineStore } from 'pinia';
import { getRegForm } from '@/services/regForm';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import { RegFormInterface } from '~/types/regForm.interface';

export const useStoreRegForm = defineStore('regForm', {
  state: () => ({
    data: {} as RegFormInterface,
    status: LoadingStatusEnum.noData as LoadingStatusEnum,
  }),
  getters: {
    formLang(state) {
      return state.data.lang;
    },
    formData(state) {
      return state.data;
    },
    isLoading(state): boolean {
      return state.status === LoadingStatusEnum.loading;
    },
    isLoaded(state): boolean {
      return state.status === LoadingStatusEnum.loaded;
    },
  },
  actions: {
    async load(lang: string): Promise<void> {
      if (!this.isLoaded) {
        this.status = LoadingStatusEnum.loading;
      }

      // const host = `${location.host}/dinamic-content`;
      const host = `${
        location.host === 'localhost' || location.host === 'local.7bitcasino.com'
          ? '7bitcasino.com'
          : location.host
      }/dinamic-content`;
      const data = (await getRegForm(host, lang)) as RegFormInterface;

      if (data.lang) {
        this.data = data;
        this.status = LoadingStatusEnum.loaded;
      } else {
        this.status = LoadingStatusEnum.error;
      }
    },
  },
});
